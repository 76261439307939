<template>
  <Card>
    <div v-if="loading">
      <div v-for="i in 4" :key="i" class="mt-8">
        <Skeleton height="0.8rem" width="4rem" />
        <Skeleton width="40%" height="1rem" class="mt-2" />
      </div>
    </div>
    <div class="mt-4" v-else>
      <h3 class="text-black font-semibold text-lg">Account Information</h3>
      <p class="text-grey text-sm">Personalize your settings</p>
      <!-- <div class="md:grid grid-cols-3 content-center mt-12">
        <div class="flex items-center">
          <p class="text-grey text-sm">SELFIE</p>
        </div>
        <div class="h-20 w-20">
          <transition name="fade">
            <img
              v-show="showProfileImage"
              class="h-20 w-20 rounded-full"
              :src="profilePicture"
              alt="profile picture"
              @error="handleImageError"
              @load="showProfileImage = true"
            />
          </transition>
        </div>
        <div class="flex items-center">
          <div class="w-full">
            <Button text="Change Selfie" width="w-full" outline />
          </div>
        </div>
      </div>
      <Hr /> -->
      <div class="md:grid grid-cols-3 py-2 mt-12">
        <p class="text-grey text-sm">NAME</p>
        <p class="capitalize">
          {{ userBio.firstname | capitalize }}
          {{ userBio.lastname | capitalize }}
        </p>
      </div>

      <Hr />
      <div class="md:grid grid-cols-3 py-2">
        <p class="text-grey test-sm">EMAIL</p>
        <p>{{ userBio.email }}</p>
      </div>
      <Hr />
      <div class="md:grid grid-cols-3 py-2">
        <p class="text-grey text-sm">PHONE NUMBER</p>
        <p v-if="userBio.phoneNumber">{{ userBio.phoneNumber }}</p>
        <p v-else>N/A</p>
      </div>
      <Hr />
      <div class="md:grid grid-cols-3 py-2">
        <p class="text-grey text-sm">ADDRESS</p>
        <p v-if="userBio.lga">{{ userBio.address }}, {{ userBio.state }}</p>
        <p v-else>N/A</p>
      </div>
    </div>
  </Card>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { Button } from "@/UI/Button";
import Hr from "@/UI/Hr";
export default {
  components: {
    Button,
    Hr,
  },
  data: () => ({
    loading: false,
    source: "",
    profilePicture: "",
    showProfileImage: false,
  }),

  computed: {
    ...mapState({
      userBio: (state) => state?.auth?.user,
      dashboardData: (state) => state?.dashboard?.dashboardData,
    }),
  },
  mounted() {
    if (this.userBio?.customerFacialImageId) {
      this.profilePicture = `${process.env.VUE_APP_BASE_URL}/media/image/${this.userBio?.customerFacialImageId}`;
    } else {
      this.profilePicture = `https://res.cloudinary.com/zillaafrica/image/upload/v1645631404/customer/Group_513_qhzxly.svg`;
    }
    if(Object.keys(this.dashboardData).length === 0){
      this.getDashboardData();
    }
    
  },
  methods: {
    ...mapActions("dashboard", ["getDashboardData"]),
    handleImageError() {
      this.profilePicture = require("@/assets/icons/profile.png");
    },
  },
};
</script>
