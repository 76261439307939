var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Card", [
    _vm.loading
      ? _c(
          "div",
          _vm._l(4, function (i) {
            return _c(
              "div",
              { key: i, staticClass: "mt-8" },
              [
                _c("Skeleton", { attrs: { height: "0.8rem", width: "4rem" } }),
                _c("Skeleton", {
                  staticClass: "mt-2",
                  attrs: { width: "40%", height: "1rem" },
                }),
              ],
              1
            )
          }),
          0
        )
      : _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("h3", { staticClass: "text-black font-semibold text-lg" }, [
              _vm._v("Account Information"),
            ]),
            _c("p", { staticClass: "text-grey text-sm" }, [
              _vm._v("Personalize your settings"),
            ]),
            _c("div", { staticClass: "md:grid grid-cols-3 py-2 mt-12" }, [
              _c("p", { staticClass: "text-grey text-sm" }, [_vm._v("NAME")]),
              _c("p", { staticClass: "capitalize" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("capitalize")(_vm.userBio.firstname)) +
                    " " +
                    _vm._s(_vm._f("capitalize")(_vm.userBio.lastname)) +
                    " "
                ),
              ]),
            ]),
            _c("Hr"),
            _c("div", { staticClass: "md:grid grid-cols-3 py-2" }, [
              _c("p", { staticClass: "text-grey test-sm" }, [_vm._v("EMAIL")]),
              _c("p", [_vm._v(_vm._s(_vm.userBio.email))]),
            ]),
            _c("Hr"),
            _c("div", { staticClass: "md:grid grid-cols-3 py-2" }, [
              _c("p", { staticClass: "text-grey text-sm" }, [
                _vm._v("PHONE NUMBER"),
              ]),
              _vm.userBio.phoneNumber
                ? _c("p", [_vm._v(_vm._s(_vm.userBio.phoneNumber))])
                : _c("p", [_vm._v("N/A")]),
            ]),
            _c("Hr"),
            _c("div", { staticClass: "md:grid grid-cols-3 py-2" }, [
              _c("p", { staticClass: "text-grey text-sm" }, [
                _vm._v("ADDRESS"),
              ]),
              _vm.userBio.lga
                ? _c("p", [
                    _vm._v(
                      _vm._s(_vm.userBio.address) +
                        ", " +
                        _vm._s(_vm.userBio.state)
                    ),
                  ])
                : _c("p", [_vm._v("N/A")]),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }